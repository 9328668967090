import React from "react";

import './footer.css'
import proyecto1 from '../../Images/proyecto1.png'
import proyecto2 from '../../Images/proyecto2.png'
import proyecto3 from '../../Images/proyecto3.png'

export default function Footer() {
    return (
        <footer>
            <div className="footer-holder">
                <img src={proyecto1} className="proyecto-image" alt="proy1"/>
                <p className="proyecto-text"><center>Proyecto RED_ES 2020 (Nº Exp. 2020/0720/00100007) “deepTAM: tecnología deep learning en sistemas avanzados de control del engorde de animales porcinos de granja”, para el desarrollo de una serie de productos TAM (Deep Bot y Deep Cam) basados en tecnología deep learning e IA más eficientes en la granja. Este proyecto ha sido financiado en el marco de la convocatoria de ayudas C007/20-ED y ha sido cofinanciado por el Fondo Europeo de Desarrollo Regional.</center></p>
                <img src={proyecto2} className="proyecto-image" alt="proy1"/>
                <p className="proyecto-text"><center>Proyecto TAM-AI (Nº Exp. IDI-20210942) “Plataforma cloud con inteligencia artificial para la gestión y control de cerdos en granja: TAM-AI”. Este proyecto está subvencionado por el CDTI.</center></p>
                <img src={proyecto3} className="proyecto-image" alt="proy1"/>
                <p className="proyecto-text"><center>Proyecto TAM-UX (Nº Exp. SAV-20221075) “TAM-UX, Nueva herramienta basada en técnicas audiovisuales para la transformación digital de la ganadería”. Este proyecto está subvencionado por el CDTI, y apoyado por el Ministerio de Asuntos Económicos y Transformación Digital.</center></p>
            </div>
        </footer>
    );
}