import React from "react";

import './navbar.css'
import logoTam from '../../Images/TamBot_Logo_V02_Black.svg'

export default function Navbar() {
    return (
        <div className='top-panel'>
            <div className="logo-background">
                <img src={logoTam} className="logo-image" alt="tam-logo" />
                <p className="logo">Granja1</p>
            </div>
            
            <ul className="menu-list">
                <a href='/'>Inicio</a>
                <a href='/farms'>Farms</a>
                <a href='/info'>Info</a>
            </ul>
        </div>
    );
}
