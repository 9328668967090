import React from 'react';

import './pen.css';


export class Pen extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            data: {
                inventory:0,
                av_weight:0,
                av_temp:0,
                sd_weight:0,
                sd_temp:0,
                
            },
            error: true
        }

        this.handleClick.bind(this);

        this.penid = this.props.pen_id;

        this.requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ penid: this.penid })
        }
    }

    
    handleClick = () => {
        this.props.changeState({seegraph:true,redirectedPen:this.penid});
    }

    updateData = function(res){
        if (res.error){
            this.setState({ error: res.error })
        }
        else{
            this.setState({
                data: {
                    inventory: res.inventory,
                    av_weight: res.av_weight,
                    av_temp: res.av_temp,
                    sd_weight: res.sd_weight,
                    sd_temp: res.sd_temp,
                },
                error: res.error
            })
        }
    }

    render() {
        if (this.props.data !== undefined){
            return (
                <div className='pen-container' onClick={this.handleClick}>
                    <h1 className='header-pen'>{this.penid}</h1>
                    <h3 className='inventory-pen'>{this.props.data.inventory}</h3>
                    <p className='weight-pen'>{this.props.data.av_weight} +- {this.props.data.sd_weight} kg</p>
                    <p className='temp-pen'>{this.props.data.av_temp} +- {this.props.data.sd_temp} ºC</p>
                </div>
            )
        }
        else{
            return (
                <div className='pen-container'>
                    <h1 className='header-pen'>{this.penid}</h1>
                </div>
            )
        }
    }
}

export function Gap(){
    return (
        <div className='gap-extender'></div>
    )
}

