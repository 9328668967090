import React from 'react';

import './farms.css'

import { Pen, Gap } from '../Pen/pen_v3';
import Info from '../Info/info_v3';

export default class Farms extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            seegraph: false,
            redirectedPen: undefined,
            data: []
        }
        this.changeState.bind(this);

        this.requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
        }

    }

    componentDidMount(){
        fetch("https://yourdatafarm.com/backendrun/api/allpen",this.requestOptions)
        .then(response => response.json())
        .then(result => this.loadData(result.data))
    }

    loadData = function(res){
        
        this.setState({data: res})
        
    }

    

    changeState = (newState) => {
        this.setState({
            seegraph: newState.seegraph,
            redirectedPen: newState.redirectedPen 
        })
    }

    render() {
        if (!this.state.seegraph){
            return (
                <div className='grid-container'>
                    <Pen changeState={this.changeState} pen_id={70} data={this.state.data[70]}/>
                    <Gap />
                    <Pen changeState={this.changeState} pen_id={50} data={this.state.data[50]}/>
                    <Pen changeState={this.changeState} pen_id={31} data={this.state.data[31]}/>
                    <Gap />
                    <Pen changeState={this.changeState} pen_id={10} data={this.state.data[10]}/>
                    <Pen changeState={this.changeState} pen_id={71} data={this.state.data[71]}/>
                    <Gap /> 
                    <Pen changeState={this.changeState} pen_id={49} data={this.state.data[49]}/>
                    <Pen changeState={this.changeState} pen_id={32} data={this.state.data[32]}/>
                    <Gap />
                    <Pen changeState={this.changeState} pen_id={9}  data={this.state.data[9]}/>
                    <Pen changeState={this.changeState} pen_id={72} data={this.state.data[72]}/>
                    <Gap />
                    <Pen changeState={this.changeState} pen_id={48} data={this.state.data[48]}/>
                    <Pen changeState={this.changeState} pen_id={33} data={this.state.data[33]}/>
                    <Gap />
                    <Pen changeState={this.changeState} pen_id={8}  data={this.state.data[8]}/>
                    <Pen changeState={this.changeState} pen_id={73} data={this.state.data[73]}/>
                    <Gap />
                    <Pen changeState={this.changeState} pen_id={47} data={this.state.data[47]}/>
                    <Pen changeState={this.changeState} pen_id={34} data={this.state.data[34]}/>
                    <Gap />
                    <Pen changeState={this.changeState} pen_id={7}  data={this.state.data[7]}/>
                    <Pen changeState={this.changeState} pen_id={74} data={this.state.data[74]}/>
                    <Gap />
                    <Pen changeState={this.changeState} pen_id={46} data={this.state.data[46]}/>
                    <Pen changeState={this.changeState} pen_id={35} data={this.state.data[35]}/>
                    <Gap />
                    <Pen changeState={this.changeState} pen_id={6} data={this.state.data[6]}/>
                    <Pen changeState={this.changeState} pen_id={75} data={this.state.data[75]}/>
                    <Gap />
                    <Pen changeState={this.changeState} pen_id={45} data={this.state.data[45]}/>
                    <Pen changeState={this.changeState} pen_id={36} data={this.state.data[36]}/>
                    <Gap />
                    <Pen changeState={this.changeState} pen_id={5} data={this.state.data[5]}/>
                    <Pen changeState={this.changeState} pen_id={76} data={this.state.data[76]}/>
                    <Gap />
                    <Pen changeState={this.changeState} pen_id={44} data={this.state.data[44]}/>
                    <Pen changeState={this.changeState} pen_id={37} data={this.state.data[37]}/>
                    <Gap />
                    <Pen changeState={this.changeState} pen_id={4}  data={this.state.data[4]}/>
                    <Pen changeState={this.changeState} pen_id={77} data={this.state.data[77]}/>
                    <Gap />
                    <Pen changeState={this.changeState} pen_id={43} data={this.state.data[43]}/>
                    <Pen changeState={this.changeState} pen_id={38} data={this.state.data[38]}/>
                    <Gap />
                    <Pen changeState={this.changeState} pen_id={3} data={this.state.data[3]}/>
                    <Pen changeState={this.changeState} pen_id={78} data={this.state.data[78]}/>
                    <Gap />
                    <Pen changeState={this.changeState} pen_id={42} data={this.state.data[42]}/>
                    <Pen changeState={this.changeState} pen_id={39} data={this.state.data[39]}/>
                    <Gap />
                    <Pen changeState={this.changeState} pen_id={2} data={this.state.data[2]}/>
                    <Pen changeState={this.changeState} pen_id={79} data={this.state.data[79]}/>
                    <Gap />
                    <Pen changeState={this.changeState} pen_id={41} data={this.state.data[41]}/>
                    <Pen changeState={this.changeState} pen_id={40} data={this.state.data[40]}/>
                    <Gap />
                    <Pen changeState={this.changeState} pen_id={1} data={this.state.data[1]}/>

                </div>
            )
        }
        else {
            return (
                <Info state={this.state} changeState={this.changeState}></Info>
            )
        }
    }
}
